.game-launcher {
  @include main-border(2px, 30px);
  display: grid;
  justify-content: space-around;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 10px;
  padding: 0 16px;
  gap: 16px;
  background: url(~assets/images/hexagon-pattern.png);
  background-size: 200px;

  &-image {
    @include media-breakpoint-down(md) {
      margin: 0 auto;
    }
  }

  &-header {
    text-align: center;
  }

  &-button {
    white-space: nowrap;
    width: 200px;

    &-container {
      display: flex;
      justify-content: end;

      @include media-breakpoint-down(md) {
        justify-content: center;
      }
    }
  }

  @include media-breakpoint-down(md) {
    grid-template-columns: 1fr;
    padding: 16px;
    text-align: center;
  }
}
