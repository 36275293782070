/* Generated by Font Squirrel (https://www.fontsquirrel.com) on October 7, 2016 */

@font-face {
  font-family: 'geomanistregular';
  src: url('geomanist-regular-webfont.eot');
  src: url('geomanist-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('geomanist-regular-webfont.woff2') format('woff2'),
    url('geomanist-regular-webfont.woff') format('woff'),
    url('geomanist-regular-webfont.ttf') format('truetype'),
    url('geomanist-regular-webfont.svg#geomanistregular') format('svg');
  font-weight: 500;
  font-style: normal;
}
