.bonded-tokens {
  margin-top: 24px;
  overflow: hidden;

  &-image {
    max-width: 100px;
    height: 100%;
    display: block;
    margin: 0 auto;
  }

  &.card .card-body {
    padding: 0;
  }

  &-header {
    &-summary {
      @include flex-between();
      margin-top: 24px;
    }
  }

  &-table {
    font-size: $font-small;

    &-header {
      margin-top: 12px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 12px 0;
      font-weight: $font-bold;

      .amount {
        text-align: right;
      }

      .amount,
      .time {
        flex-basis: 50%;
      }

      .time-amount-separator {
        display: none;
      }
    }

    &-item {
      @include flex-between();
      flex-wrap: wrap;
      padding: 4px 10px;

      .amount {
        text-align: right;
      }

      &.ready {

        .amount,
        .time {
          font-weight: $font-bold;
        }
      }

      .amount,
      .time {
        flex-basis: 50%;
      }

      &:nth-child(2n) {
        background: rgba(27, 11, 91, 0.2);
        border-radius: 5px;
      }
    }
  }

  &-buttons {
    display: flex;
    align-items: center;
    margin-top: 24px;
    gap: 20px;

    .btn {
      padding: 10px 35px;
      width: 100%;
    }

    .fee-text {
      text-align: center;
      font-size: $font-extra-small;
    }
  }

  &-button-wrapper {
    flex-basis: 50%;
  }

  @include media-breakpoint-down(md) {
    &-header {
      &-summary {
        flex-wrap: wrap;
      }
    }

    &-table {
      &-header {
        display: block;
        padding: 12px 0;

        .time,
        .amount {
          display: inline;
        }

        .time-amount-separator {
          display: inline;
        }
      }
    }

    &-buttons {
      flex-wrap: wrap;
    }

    &-button-wrapper {
      flex-basis: 100%;
    }
  }
}