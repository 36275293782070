.data-item {
  display: flex;

  &-icon {
    @include text-background-gold;
    width: 36px;
    font-size: 32px;
    margin-right: 16px;
  }

  &-header {
    @include text-background-gold;
    white-space: nowrap;
    font-size: $font-extra-small;
    font-weight: $font-bold;
  }

  &-data {
    font-size: $font-small;
    font-weight: $font-bold;
  }
}
