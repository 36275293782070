// BASED ON VARIABLES FILE in node modules / bootstrap
$body-color: $white;
$font-family-base: 'geomanistregular';

$grid-breakpoints: (
  xs: 0,
  sm: 480px,
  md: 768px,
  lg: 992px,
  xl: 1280px,
);

$container-max-widths: (
  sm: 460px,
  md: 720px,
  lg: 960px,
  xl: 1240px,
);

// Progress bars
// $progress-height:                   1rem !default;
// $progress-font-size:                $font-size-base * .75 !default;
$progress-bg: $gold-dark-5;
// $progress-border-radius: $border-radius !default;
// $progress-box-shadow: $box-shadow-inset !default;
// $progress-bar-color: $white !default;
$progress-bar-bg: $violet-dark-9-alpha;
// $progress-bar-animation-timing: 1s linear infinite !default;
// $progress-bar-transition: width 0.6s ease !default;

// Color system

// scss-docs-start gray-color-variables
// $white: #fff !default;
// $gray-100: #f8f9fa !default;
// $gray-200: #e9ecef !default;
// $gray-300: #dee2e6 !default;
// $gray-400: #ced4da !default;
// $gray-500: #adb5bd !default;
// $gray-600: #6c757d !default;
// $gray-700: #495057 !default;
// $gray-800: #343a40 !default;
// $gray-900: #212529 !default;
// $black: #000 !default;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
// $grays: (
//   '100': $gray-100,
//   '200': $gray-200,
//   '300': $gray-300,
//   '400': $gray-400,
//   '500': $gray-500,
//   '600': $gray-600,
//   '700': $gray-700,
//   '800': $gray-800,
//   '900': $gray-900,
// ) !default;
// scss-docs-end gray-colors-map
// fusv-enable

// scss-docs-start zindex-stack
// $zindex-dropdown: 1000 !default;
// $zindex-sticky: 1020 !default;
// $zindex-fixed: 1030 !default;
// $zindex-offcanvas-backdrop: 1040 !default;
// $zindex-offcanvas: 1045 !default;
// $zindex-modal-backdrop: 1050 !default;
// $zindex-modal: 1055 !default;
// $zindex-popover: 1070 !default;
// $zindex-tooltip: 1080 !default;
// $zindex-toast: 1090 !default;
// scss-docs-end zindex-stack

// Navs

// scss-docs-start nav-variables
// $nav-link-padding-y: 0.5rem !default;
// $nav-link-padding-x: 1rem !default;
// $nav-link-font-size: null !default;
// $nav-link-font-weight: null !default;
// $nav-link-color: var(--#{$prefix}link-color) !default;
// $nav-link-hover-color: var(--#{$prefix}link-hover-color) !default;
// $nav-link-transition: color 0.15s ease-in-out,
//   background-color 0.15s ease-in-out, border-color 0.15s ease-in-out !default;
// $nav-link-disabled-color: $gray-600 !default;

// $nav-tabs-border-color: $gray-300 !default;
// $nav-tabs-border-width: $border-width !default;
// $nav-tabs-border-radius: $border-radius !default;
// $nav-tabs-link-hover-border-color: $gray-200 $gray-200 $nav-tabs-border-color !default;
// $nav-tabs-link-active-color: $gray-700 !default;
// $nav-tabs-link-active-bg: $body-bg !default;
// $nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg !default;

$nav-pills-border-radius: $main-border-radius-small !default;
// $nav-pills-link-active-color: $component-active-color !default;
// $nav-pills-link-active-bg: $component-active-bg !default;
// scss-docs-end nav-variables

// TODO: hover
// scss-docs-start accordion-variables
$accordion-padding-y: 32px !default;
$accordion-padding-x: 32px !default;
$accordion-color: $body-color !default;
// $accordion-bg:                            $body-bg !default;
$accordion-border-width: 1px !default;
$accordion-border-color: $main-border-color; // var(--#{$prefix}border-color) !default;
$accordion-border-radius: $main-border-radius !default;
// $accordion-inner-border-radius:           subtract($accordion-border-radius, $accordion-border-width) !default;

// $accordion-body-padding-y:                $accordion-padding-y !default;
// $accordion-body-padding-x:                $accordion-padding-x !default;

// $accordion-button-padding-y:              $accordion-padding-y !default;
// $accordion-button-padding-x:              $accordion-padding-x !default;
// $accordion-button-color:                  $accordion-color !default;
// $accordion-button-bg:                     var(--#{$prefix}accordion-bg) !default;
// $accordion-transition:                    $btn-transition, border-radius .15s ease !default;
// $accordion-button-active-bg:              tint-color($component-active-bg, 90%) !default;
// $accordion-button-active-color:           shade-color($primary, 10%) !default;

$accordion-button-focus-border-color: $main-border-color !default;
$accordion-button-focus-box-shadow: none !default;

// $accordion-icon-width:                    1.25rem !default;
$accordion-icon-color: $white !default;
$accordion-icon-active-color: $white !default;
// $accordion-icon-transition:               transform .2s ease-in-out !default;
// $accordion-icon-transform:                rotate(-180deg) !default;

// $accordion-button-icon:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
// $accordion-button-active-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
// scss-docs-end accordion-variables

//
// Dropdown menu container and contents.

// scss-docs-start dropdown-variables
// $dropdown-min-width:                10rem !default;
// $dropdown-padding-x:                0 !default;
// $dropdown-padding-y:                .5rem !default;
// $dropdown-spacer:                   .125rem !default;
// $dropdown-font-size:                $font-size-base !default;
$dropdown-color: $body-color !default;
$dropdown-bg: $violet-dark-9-alpha !default;
// $dropdown-border-color:             var(--#{$prefix}border-color-translucent) !default;
// $dropdown-border-radius:            $border-radius !default;
// $dropdown-border-width:             $border-width !default;
// $dropdown-inner-border-radius:      subtract($dropdown-border-radius, $dropdown-border-width) !default;
// $dropdown-divider-bg:               $dropdown-border-color !default;
// $dropdown-divider-margin-y:         $spacer * .5 !default;
// $dropdown-box-shadow:               $box-shadow !default;

$dropdown-link-color: $white;
// $dropdown-link-hover-color:         shade-color($dropdown-link-color, 10%) !default;
$dropdown-link-hover-bg: transparent !default;

// $dropdown-link-active-color:        $component-active-color !default;
// $dropdown-link-active-bg:           $component-active-bg !default;

// $dropdown-link-disabled-color:      $gray-500 !default;

// $dropdown-item-padding-y:           $spacer * .25 !default;
// $dropdown-item-padding-x:           $spacer !default;

// $dropdown-header-color:             $gray-600 !default;
// $dropdown-header-padding-x:         $dropdown-item-padding-x !default;
// $dropdown-header-padding-y:         $dropdown-padding-y !default;
// fusv-disable
// $dropdown-header-padding:           $dropdown-header-padding-y $dropdown-header-padding-x !default; // Deprecated in v5.2.0
// fusv-enable
// scss-docs-end dropdown-variables

// scss-docs-start dropdown-dark-variables
// $dropdown-dark-color:               $gray-300 !default;
// $dropdown-dark-bg:                  $gray-800 !default;
// $dropdown-dark-border-color:        $dropdown-border-color !default;
// $dropdown-dark-divider-bg:          $dropdown-divider-bg !default;
// $dropdown-dark-box-shadow:          null !default;
// $dropdown-dark-link-color:          $dropdown-dark-color !default;
// $dropdown-dark-link-hover-color:    $white !default;
// $dropdown-dark-link-hover-bg:       rgba($white, .15) !default;
// $dropdown-dark-link-active-color:   $dropdown-link-active-color !default;
// $dropdown-dark-link-active-bg:      $dropdown-link-active-bg !default;
// $dropdown-dark-link-disabled-color: $gray-500 !default;
// $dropdown-dark-header-color:        $gray-500 !default;
// scss-docs-end dropdown-dark-variables

// Modals

// scss-docs-start modal-variables
$modal-inner-padding: 32px !default;

// $modal-footer-margin-between:       .5rem !default;

// $modal-dialog-margin:               .5rem !default;
// $modal-dialog-margin-y-sm-up:       1.75rem !default;

// $modal-title-line-height:           $line-height-base !default;

// $modal-content-color:               null !default;
$modal-content-bg: $violet-dark-9-alpha-2;
// $modal-content-border-color:        var(--#{$prefix}border-color-translucent) !default;
// $modal-content-border-width:        $border-width !default;
$modal-content-border-radius: $main-border-radius !default;
// $modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width) !default;
// $modal-content-box-shadow-xs:       $box-shadow-sm !default;
// $modal-content-box-shadow-sm-up:    $box-shadow !default;

$modal-backdrop-bg: rgb(51, 33, 108) !default;
// $modal-backdrop-opacity:            .5 !default;

// $modal-header-border-color:         var(--#{$prefix}border-color) !default;
// $modal-header-border-width:         $modal-content-border-width !default;
// $modal-header-padding-y:            $modal-inner-padding !default;
// $modal-header-padding-x:            $modal-inner-padding !default;
// $modal-header-padding:              $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

// $modal-footer-bg:                   null !default;
// $modal-footer-border-color:         $modal-header-border-color !default;
// $modal-footer-border-width:         $modal-header-border-width !default;

// $modal-sm:                          300px !default;
// $modal-md:                          500px !default;
// $modal-lg:                          800px !default;
// $modal-xl:                          1140px !default;

// $modal-fade-transform:              translate(0, -50px) !default;
// $modal-show-transform:              none !default;
// $modal-transition:                  transform .3s ease-out !default;
// $modal-scale-transform:             scale(1.02) !default;
// scss-docs-end modal-variables

// scss-docs-start form-check-variables
// $form-check-input-width:                  1em !default;
// $form-check-min-height:                   $font-size-base * $line-height-base !default;
// $form-check-padding-start:                $form-check-input-width + .5em !default;
// $form-check-margin-bottom:                .125rem !default;
// $form-check-label-color:                  null !default;
// $form-check-label-cursor:                 null !default;
// $form-check-transition:                   null !default;

// $form-check-input-active-filter:          brightness(90%) !default;

// $form-check-input-bg:                     $input-bg !default;
// $form-check-input-border:                 1px solid rgba($black, .25) !default;
// $form-check-input-border-radius:          .25em !default;
// $form-check-radio-border-radius:          50% !default;
$form-check-input-focus-border: $violet-dark-9-alpha !default;
$form-check-input-focus-box-shadow: 0 0 0 0.25rem $violet-dark-9-alpha !default;

// $form-check-input-checked-color:          $component-active-color !default;
$form-check-input-checked-bg-color: $violet-dark-9-alpha !default;
$form-check-input-checked-border-color: $white !default;
// $form-check-input-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/></svg>") !default;
// $form-check-radio-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>") !default;

// $form-check-input-indeterminate-color:          $component-active-color !default;
// $form-check-input-indeterminate-bg-color:       $component-active-bg !default;
// $form-check-input-indeterminate-border-color:   $form-check-input-indeterminate-bg-color !default;
// $form-check-input-indeterminate-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>") !default;

// $form-check-input-disabled-opacity:        .5 !default;
// $form-check-label-disabled-opacity:        $form-check-input-disabled-opacity !default;
// $form-check-btn-check-disabled-opacity:    $btn-disabled-opacity !default;

// $form-check-inline-margin-end:    1rem !default;
// scss-docs-end form-check-variables
