.message {
  margin-top: 16px !important;
  padding: 16px;
  border-radius: 8px;

  &-header {
    font-size: $font-medium;
    font-weight: $font-regular;
    flex-grow: 1;
  }

  &-description {
    font-size: $font-extra-small;
    font-weight: $font-regular;
  }

  &-header + &-description {
    margin-top: 16px;
  }

  &.error {
    color: $message-error;
    border: 1px solid $message-error-border;
    background-color: $message-error-bg;
  }

  &.primary {
    color: $message-primary;
    border: 1px solid $message-primary-border;
    background-color: $message-primary-bg;
  }
}
