.link {
  color: $white;
  text-decoration: none;
  font-weight: $font-regular;
  pointer-events: auto;

  &:hover {
    color: $grey-hover;
  }
}

a[aria-disabled='true'],
a[disabled] {
  @extend .inactive;
}

.link-external {
  &::after {
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    content: "\f08e";
    font-size: 0.7em;
    margin-left: 0.3em;
    vertical-align: text-bottom;
  }
}