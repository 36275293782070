.header {
  width: 100%;

  .navbar {
    padding: 12px 0;

    &-nav {
      .nav-link {
        @include text-background-white-gray;
        font-size: $font-small;
        font-weight: $font-bold;
        padding: 8px 16px;

        &:hover {
          color: $grey-hover;
          -webkit-text-fill-color: initial;
        }
      }

      .nav-item {
        &.dropdown {
          .dropdown-menu {
            .dropdown-item {
              font-weight: $font-bold;
              font-size: $font-small;

              &:hover {
                color: $grey-hover;
              }
            }
          }

          .fa-power-off {
            position: absolute;
            top: 1rem;
            right: 1rem;
          }

          @include media-breakpoint-down(md) {
            .dropdown-menu {
              border: none;
              background: transparent;
              padding: 0 0 0 20px;
              position: relative;

              .dropdown-item {
                color: $white;
              }
            }
          }

          @include media-breakpoint-up(lg) {
            .dropdown-menu {
              border-radius: 16px;
              border: none;
              box-shadow: 0 4px 4px 0 #00000040;

              .dropdown-item {
                &:hover {
                  background-color: transparent;
                }
              }
            }
          }
        }
      }

      .link-external {
        &::after {
          vertical-align: 2px;
        }
      }
    }
  }

  // .coming-soon {
  //   position: absolute;
  //   color: red;
  //   font-size: $font-tiny;
  //   top: -5px;
  //   left: 50%;
  //   white-space: nowrap;
  //   font-weight: $font-bold;
  //   user-select: none;

  //   &-item {
  //     position: relative;
  //     width: fit-content;
  //   }
  // }

  .wallet-section {
    @include media-breakpoint-down(md) {
      &>.dropdown>.dropdown-menu {
        background-color: var(--bs-dropdown-bg) !important;

      }
    }

    &>.dropdown {
      &>.nav-link {
        @include text-background-gold;
        color: var(--bs-link-color);

        &:hover {
          @include text-background-off;
          color: var(--bs-link-hover-color);
        }
      }
    }

    &>.dropdown.danger {
      &>.nav-link {
        @include text-background-off;
        color: red;

        &:hover {
          color: rgb(220, 0, 0);
        }
      }
    }

    &>.dropdown>.dropdown-menu {
      left: auto !important;
      right: 0 !important;
      padding: 20px !important;

      >* {
        text-align: center;
      }

      .dropdown-header {
        color: white;
      }
    }

    .chain-selector {
      button {
        padding: 3px 12px;
        min-height: 0;
        font-size: small;
        margin: auto;
      }

      .dropdown-menu {
        background-color: $violet-dark-9 !important;
        min-width: 0;
        padding: 10px !important;
        left: 50%;
        transform: translateX(-50%);

        .dropdown-item {
          text-align: center;
          // font-size: small !important;
        }

      }
    }
  }
}