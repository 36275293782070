.info-card {
  position: relative;
  background-color: $background !important;
  border-radius: 30px;
  padding: 32px;
  margin: 10px;

  * {
    margin: auto;
  }

  &-header {
    @include text-background-gold;
    font-size: $font-extra-large;
    font-weight: $font-bold;
    text-transform: uppercase;
    margin-bottom: 16px;
    text-align: center;
  }

  &-description {
    text-align: center;
  }

  &-description:not(:last-child) {
    margin-bottom: 16px;
  }
}