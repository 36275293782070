.nfts {
  &-header {
    font-size: 40px;
    font-weight: $font-bold;
  }
}

.nft {
  &-items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;
    margin-top: 16px;

    @include media-breakpoint-down(md) {
      grid-template-columns: 1fr 1fr;
    }

    @include media-breakpoint-down(sm) {
      grid-template-columns: 1fr;
    }
  }

  &-item {
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    border: 1px solid transparent;

    &.selected {
      border-color: $gold-dark-5;
    }

    &-image {
      max-width: 100%;
      filter: brightness(1.1);
    }

    &-tier,
    &-edition {
      color: $white;
      text-align: center;
    }

    &-tier {
      position: absolute;
      top: 5px;
      left: 8px;
    }

    &-edition {
      position: absolute;
      top: 5px;
      right: 8px;
    }
  }
}

.create-game-account-modal {
  .nft-item {
    &:hover {
      filter: brightness(1.1);
      cursor: pointer;
    }
  }
}
