.info-bar {
  @include main-border;
  border-radius: 10px;
  padding: 24px 0;
  margin: 0 10px;

  &-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;

    // TODO: Fix globally
    .data-item {
      flex-basis: 0;

      &-header {
        color: $grey-light-1;
      }

      &-data {
        white-space: nowrap;
      }
    }

    @include media-breakpoint-down(md) {
      display: block;

      & > * {
        margin: 10px 0;
      }
    }
  }
  @include media-breakpoint-down(md) {
    &-button {
      width: 100%;
    }
  }
}
