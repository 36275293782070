.card {
  border: none;
  border-radius: $main-border-radius;
  padding: 16px 24px;
  background-color: transparent;

  // &-header {
  //   min-height: 78px;
  //   background: $color-dark;
  //   padding: 28px 44px;
  // }

  // &.loading {
  //   pointer-events: none;
  // }

  // &:not(.loading) {
  //   .card-header {
  //     cursor: pointer;

  //     &:hover {
  //       background-color: $color-dark-hover;
  //     }
  //   }
  // }

  // .collapse,
  // .collapsing {
  //   border-top: 1px solid $main-border-color;
  // }

  // .card-body {
  //   @include dark-background-with-backdrop();
  //   padding: 28px 44px;
  // }

  @include media-breakpoint-down(md) {
    padding: 16px;
  }
}

.nav-pills {
  flex-wrap: nowrap;

  .nav-item {
    text-align: center;
    flex-grow: 1;

    .nav-link {
      @include flex-centered;
      @include main-border;
      background: $violet-gradient;
      font-weight: $font-bold;
      height: 45px;

      &:not(.disabled) {
        color: $white;
      }

      &:hover {
        position: relative;
        z-index: 1;
        opacity: 1;
        background: $card-background;
      }
    }

    .nav-link.active,
    .show>.nav-link {
      opacity: 1;
      background: $violet-dark-9;
    }

    &:first-of-type {
      .nav-link {
        border-top-left-radius: $main-border-radius-small;
        border-bottom-left-radius: $main-border-radius-small;
      }
    }

    &:last-of-type {
      .nav-link {
        border-top-right-radius: $main-border-radius-small;
        border-bottom-right-radius: $main-border-radius-small;
      }
    }
  }
}

.progress {
  border: 1px solid $primary;
}

.accordion {
  &-item {
    background: transparent;
    @include main-border(1px, 16px);
  }

  &-body {
    background: $card-background;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  &-header {
    .accordion-button {
      background: $card-background;
      box-shadow: none !important;

      &[aria-expanded='false'] {
        border-bottom: none;
      }
    }

    &.loading {
      .accordion-button {
        &:after {
          display: none;
        }
      }
    }
  }

  &-collapse.show {
    .accordion-body {
      border-top: 1px solid $blue-light-alpha;
    }
  }

  @include media-breakpoint-down(md) {
    &-button {
      padding: 22px 16px;
    }

    &-body {
      padding: 22px 16px;
    }
  }
}

.modal {
  &-content {
    color: $white;
  }

  &-header {
    @include text-background-gold;
    font-size: 20px;
    font-weight: 700;
    border-bottom: none;
    padding-bottom: 0;

    .close {
      opacity: 1;
      text-shadow: none;
      color: $white;
    }
  }

  &-footer {
    border-top: none;
    padding: 0 32px 32px 32px;

    &>* {
      margin: 0;
    }
  }
}

.list-group-item {
  height: 45px;
  color: $gold-dark-5;
  background: transparent;
  border: 2px solid $gold-dark-5;
  font-weight: $font-regular;

  .active {
    background: $gold-gradient;
  }

  &:first-child {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }

  &:last-child {
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
  }

  &.active {
    color: $violet-dark-9;
  }

  &:active,
  &:focus {
    background: $gold-gradient;
    color: $violet-dark-9;
  }

  &:hover {
    background: $gold-dark-5;
  }

  &[aria-disabled='true'] {
    background: transparent;
  }
}

.tooltip-inner {
  --bs-tooltip-padding-x: 1rem;
  --bs-tooltip-padding-y: 1rem;
  --bs-tooltip-max-width: 300px;
}