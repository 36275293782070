.staking-pool {
  &+.staking-pool {
    margin-top: 32px;
  }

  &-header {
    font-size: $font-extra-large;
    font-weight: $font-bold;
    margin-bottom: 16px;
    text-transform: uppercase;

    .pool-type {
      @include text-background-gold;
      margin-right: 8px;
    }
  }

  &-item {
    background: transparent;

    .accordion-button {
      // TODO
      color: $white;
    }

    &+.staking-pool-item {
      margin-top: 16px;
    }

    &.inactive {
      .staking-pool-item-header {
        .name {
          filter: grayscale(1);
        }
      }
    }

    &-details {
      margin: 0 0 16px 0;
      flex-wrap: wrap;
      gap: 10px;

      .data-item {
        @include media-breakpoint-down(md) {
          width: 100%;
        }
      }
    }

    &-header {
      @include flex-between;
      font-size: $font-small;
      flex-basis: 100%;
      margin-right: 16px;

      .name {
        @include text-background-gold;
        font-weight: $font-bold;
        flex-basis: 100%;

        &-icon {
          filter: grayscale(1);
          margin: 0 5px;
        }
      }

      .apy {
        font-weight: $font-bold;
        white-space: nowrap;
        text-align: center;

        &-limit {
          font-size: 10px;
          font-weight: normal;
        }

        &-question-icon {
          margin-left: 8px;
        }
      }

      .tvl {
        color: $white;
        font-weight: $font-bold;
        flex-basis: 100%;
        text-align: right;
      }

      @include media-breakpoint-down(md) {
        flex-wrap: wrap;

        .apy {
          text-align: left;
        }

        .tvl {
          text-align: left;
        }
      }
    }

    &-body {
      .staking-pool-item-details {
        @include flex-between();

        &+.staking-pool-item-details {
          margin-top: 16px;
        }
      }

      .pills {
        margin-bottom: 32px;
      }

      .tabs {
        .tab-content {

          .approve-section,
          .stake-section,
          .unstake-section,
          .move-bond-section {
            &-description {
              font-size: $font-small;

              &:not(:last-child) {
                margin-bottom: 16px;
              }
            }
          }

          .claim-section {
            border-radius: $main-border-radius;

            &-image {
              max-width: 100px;
              height: 100%;
              display: block;
              margin: 0 auto;
            }

            &-info {
              text-align: center;

              &-header {
                font-weight: $font-bold;
                margin-top: 16px;
              }

              &-details {
                font-weight: $font-regular;
              }
            }
          }

          .s-input {
            margin-bottom: 16px;
            width: 100%;
          }

          .percentage-button {
            margin-bottom: 16px;
          }

          .section-action-button {
            width: 100%;
          }
        }
      }
    }
  }
}