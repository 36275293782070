.footer {
  padding: 48px 0;

  &-logo {
    display: block;
    margin: 0 auto 32px auto;
    text-align: center;
  }

  .footer-input {
    width: 280px;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  .footer-description {
    margin: 16px 0 0 0;
    font-weight: $font-regular;
  }

  .footer-links {
    margin: 32px 0;
    display: flex;
    justify-content: center;
    gap: 32px;
    text-align: center;

    @include media-breakpoint-down(sm) {
      display: block;
    }

    .footer-link {
      display: block;
    }
  }

  .footer-socials {
    display: flex;
    justify-content: center;
    gap: 32px;

    .footer-social {
      @include text-background-gold;
      font-size: 32px;
    }
  }

  &-links {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-bottom: 24px;

    @include media-breakpoint-down(sm) {
      display: block;
      text-align: center;
    }
  }

  &-link {
    text-align: center;
  }

  &-logo {
    text-align: center;
  }
}
