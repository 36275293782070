.vesting {
  &-pool {
    &.card {
      min-height: 200px;
      border: $main-border;
      background: $card-background;
    }

    & + & {
      margin-top: 24px;
    }

    &-name {
      @include text-background-gold;
      display: inline-block;
      font-size: $font-extra-large;
      font-weight: $font-bold;
      margin-bottom: 24px;
    }

    &-details {
      @include flex-between();
      margin-bottom: 24px;
    }

    &-button {
      margin-top: 24px;
    }

    @media (max-width: $screen-tablet) {
      &-details {
        flex-direction: column;
        align-items: flex-start;
      }

      &-button {
        margin-top: 16px;
      }
    }
  }
}
