.s-input {
  border: $main-border;
  height: 45px;
  color: $white;
  border-radius: $main-border-radius-small;
  padding: 16px;
  font-size: $font-small;
  font-weight: $font-regular;
  background: $violet-dark-9;

  &.round {
    border-radius: 32px;
  }

  &[disabled] {
    opacity: 0.5;
  }

  &:hover:not([disabled]) {
    border: 1px solid $gold-dark-5;
    filter: brightness(1.1);
  }

  &:focus {
    outline: none;
    border-color: $primary;
  }

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $grey-hover;
    opacity: 1; /* Firefox */
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $grey-hover;
  }

  &-label {
    @include text-background-gold;
    margin-bottom: 4px;
    font-size: $font-medium;
    font-weight: $font-regular;
  }
}
