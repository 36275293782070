// $theme-colors: (
//   'primary': #f85621,
// );

// Font Awesome
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';

@import 'variables';
@import 'overrides/bootstrap';
@import 'fonts';
@import './mixins/';
@import './elements/';
@import './components/';
@import './overrides/';
@import 'helpers';

body {
  font-size: 14px;
  background-color: $background;
  color: white;
  margin: 0;
  letter-spacing: 0.5px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 72px;
}

h2 {
  font-size: 32px;
}

h1,
h2 {
  @include text-background-gold;
}

img {
  max-height: 100%;
}

// TODO: Fix background, margin and border radius
.app-container {
  background-image: url(~assets/images/clouds.png);
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  min-height: calc(100vh - #{$footer-height});
  margin: 10px 10px 0;
  border-radius: $main-border-radius-2;
  padding-bottom: 1px;

  @include media-breakpoint-down(sm) {
    min-height: calc(100vh - #{$footer-height-mobile});
  }
}

.app-content {
  box-sizing: border-box;

  @include media-breakpoint-down(sm) {
    min-height: calc(100vh - #{$footer-height-mobile});
  }
}

.container-inner {
  max-width: 768px;
  margin: 0 auto;
}

.section-container {
  border-radius: 16px;
  background: $violet-dark-9-alpha;
  backdrop-filter: blur(2px);
  margin: 32px auto;
  padding: 32px;
  max-width: 800px;
}
