.game-accounts {
  background: transparent;
}

.game-account-item {

  .accordion-header {
    .accordion-button {
      background-size: cover;
      background-position: center;
      background-blend-mode: color-burn;

    }
  }

  .staking-pool-item-header {

    :last-child {
      font-size: 16px;
      font-weight: 500;
      -webkit-text-fill-color: initial;
      text-shadow: 1px 1px 5px #000, -1px 1px 5px #000, -1px 1px 5px #000, 1px -1px 5px #000;

      .fa-ban {
        bottom: -2px;
        right: -6px;
        font-size: 20px;
        font-weight: 900;
        text-shadow: none;
        color: #ad5757;
      }
    }
  }
}

// TODO: Fix dropdown styles
// .dropdown-toggle {
//   background-color: $violet-dark-9;
//   border: $main-border;
//   justify-content: flex-start;
//   color: $grey-hover;
//   font-weight: $font-regular;

//   &:active,
//   &:focus,
//   &:focus-within,
//   &:focus-visible {
//     background-color: $violet-dark-9;
//     border-color: $gold-dark-5;
//     color: $grey-hover;
//     box-shadow: none;
//   }

//   &.btn.show {
//     background-color: $violet-dark-9;
//   }

//   &:hover {
//     border-width: 1px;
//     color: $grey-hover;
//   }
// }

// .dropdown-toggle,
// .dropdown-menu {
//   width: 100% !important;
// }

// .dropdown-toggle.show {
//   border-bottom-left-radius: 0;
//   border-bottom-right-radius: 0;
// }

// .dropdown-menu.show {
//   background-color: $violet-dark-9;
//   overflow: hidden;
//   border-top-left-radius: 0;
//   border-top-right-radius: 0;
//   border-bottom-left-radius: 24px;
//   border-bottom-right-radius: 24px;
//   border: 1px solid $gold-dark-5;
//   border-top: none;
//   margin-top: -1px;
// }

// .dropdown-item {
//   font-size: $font-small;
// }