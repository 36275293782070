.preloader {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: $background;

  &.transparent {
    background-color: rgba($background, 0.5);
  }

  z-index: $z-topmost;

  & &-container {
    width: 100%;
    height: 100%;
    position: relative;
  }

  & &-image {
    position: absolute;
    left: 50%;
    top: 50%;
    animation: preloaderKeyframes 0.75s infinite;
    animation-direction: alternate;
  }

  @keyframes preloaderKeyframes {
    0% {
      transform: translate(-50%, -50%) scale(1);
      filter: brightness(0.5) invert(1);
    }

    100% {
      transform: translate(-50%, -50%) scale(1.1);
      filter: brightness(1) invert(0);
    }
  }
}