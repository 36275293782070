.allocation-pool {
  &-item {
    .accordion-item {
      overflow: hidden;
    }

    .accordion-button {
      color: $white;
      box-shadow: none;

      &:after {
        display: none;
      }
    }

    .accordion-header {
      pointer-events: none;
    }
  }
}
