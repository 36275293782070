@mixin text-background-white-gray {
  background: $white-gray-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin text-background-gold {
  background: $gold-text-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin text-background-off {
  background-image: none;
  -webkit-text-fill-color: initial;
}