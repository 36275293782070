.web3modal {
  &-modal-card {
    width: auto !important;
  }

  &-provider {
    &-name {
      font-size: 1.1rem !important;
    }

    &-description {
      font-size: 0.9rem !important;
    }

    &-container {
      padding: 16px 12px !important;
    }
  }
}