.hero {
  max-width: 800px;
  margin: 0 auto;
  padding: 64px 0;
  color: $white;

  &-header {
    @include text-background-gold;
    font-size: 72px;
    line-height: 80px;
    font-weight: $font-light;
  }

  &-description {
    margin-bottom: 24px;
    font-size: 24px;
    line-height: 32px;
    font-weight: $font-light;
  }

  &-description-2 {
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 24px;
    font-weight: $font-light;
  }

  &-buttons-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;

    .btn {
      min-width: 90px;
    }
  }

  &-tokens-info {
    margin-top: 5rem;

    .link-secondary {
      font-size: 18px;
      color: #9a8bcd !important;

      &:hover {
        filter: brightness(0.8)
      }
    }

    // @include media-breakpoint-down(md) {
    //   .link-secondary {
    //     font-size: 2rem;
    //   }

    //   .metamask-button {
    //     img {
    //       width: 36px !important;
    //     }
    //   }
    // }

    .metamask-button {
      img {
        vertical-align: top;
        width: 23px;
        filter: hue-rotate(223deg) saturate(0.7);
      }
    }

    .fa,
    [class^="fa-"],
    [class*=" fa-"] {
      text-decoration: none;
    }

    .fa-solid {
      font-weight: 900 !important;
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 32px 0;

    &-header {
      font-size: 40px;
      line-height: 48px;
    }

    &-description {
      font-size: $font-medium;
      line-height: 24px;
    }

    &-buttons-container {
      .btn {
        flex-basis: 100%;
      }
    }
  }
}