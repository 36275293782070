.section-header {
  &:not(:last-child) {
    margin-bottom: 32px;
  }

  &-title {
    @include text-background-gold;
    display: inline-block;
    font-size: 20px;
    font-weight: $font-bold;
  }
}