.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: $font-bold;
  letter-spacing: 0.5px;
  font-size: $font-small;
  border-radius: 24px;
  transition: none;
  padding: 12px;
  min-height: 45px;

  &[disabled] {
    cursor: not-allowed;
    border: none;
  }

  &-primary {
    background: $gold-gradient;
    color: $violet-dark-9;
    border: none;

    &:hover:not([disabled]) {
      filter: brightness(1.1);
    }

    &[disabled] {
      opacity: 0.5;
    }
  }

  &-secondary {
    // @include text-background-gold;
    color: $gold-dark-5;
    border: 2px solid $gold-dark-5;
    background: transparent;

    &:hover:not([disabled]) {
      filter: brightness(1.1);
      color: $gold-dark-5;
      background: transparent;
      border: 2px solid $gold-dark-5;
    }

    &[disabled] {
      border: 2px solid #6c757d !important;
      background-color: transparent !important;
      color: #f9f9fb;
    }
  }

  // TODO: Ternary
  // &-ternary {
  //   border: 2px solid $primary-light-4;
  //   color: $primary-light-4;
  //   background: transparent;

  //   &:hover:not([disabled]) {
  //     border: 2px solid $primary-dark-7;
  //     color: $primary-dark-7;
  //     background: transparent;
  //   }

  //   &[disabled] {
  //     color: $disabled;
  //     background: $disabled-bg;
  //   }
  // }

  &-link {
    background: transparent;
    @include text-background-gold;

    &:hover:not([disabled]) {
      filter: brightness(1.1);
    }

    &[disabled] {
      opacity: 0.5;
    }
  }
}
