.percentage-buttons {
  @include flex-centered();
  gap: 5px;

  .percentage-button {
    height: 45px;
    border: $main-border;
    border-radius: $main-border-radius-small;
    flex-basis: 25%;
    background: $violet-gradient;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;

    &:hover {
      background: $card-background;
    }
  }
}
